@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.fonctionnement {
    background: url("https://mad-up.com/home/pattern-bg.jpg");
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;

    li{
        padding-bottom: 5px;
    }
    .container {
        display: grid;
        grid-template-columns: 30% 40% 30%;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    &_item {
        cursor: pointer;
    }

    &_left {
        justify-self: left;
    }

    &_center {
        justify-self: center;
    }

    &_right {
        justify-self: right;
    }

    .big_text {
        @media (max-width:768px) {
            font-size: 42px;
        }
    }
}


#round {
    position: relative;
    margin-bottom: 100px;
     background: url('https://mad-up.com/api/uploads/mad-up/originals/aplications-circle.png');
    background-position: center;
    background-size: cover;
    width: 400px;
    height: 400px;
    display: inline-flex;
    align-items: center;
    justify-content: center;



    #circle {
        position: absolute;
        width: 80%;
        height: 80%;
        transition: all 0.7s ease-out 0s;
        cursor: pointer;
        border-radius: 19px;
        -webkit-border-radius: 19px;
        -moz-border-radius: 19px;

    }

    #subcircle {
        width: 20px;
        height: 20px;
        background: url('https://mad-up.com/home/top-left-arrow.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transform: rotate(6deg);
    }

}

.fonctionnement {
    text-align: center;
    margin-top:15px;
    &_title {
        font-size: 20px;
        text-transform: uppercase;
        color: $color-5;
        background: $blue-1;
        width: 100%;
        display: block;
        padding:12px;
        max-width: 450px;
        margin: 10px auto 25px;
    }

    // &_icon {
    //     width: 100px;
    //     height: 100px;
    //     background: url('https://mad-up.com/home/aplications-icon.png');
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     display: inline-flex;
    //     align-items: center;
    //     justify-content: center;

    //     i {
    //         font-size: 40px;
    //         color: $color-blue;
    //     }
    // }

    &_text {
        width: 100%;
        display: block;
        max-width: 400px;
        margin: 10px auto 20px;
        color: #383838;
        font-size: 14px;
        text-align: left;
    }

    &_center {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        &_image {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            top: 1%;
            position: relative;
            padding: 20px;
            @media (max-width:768px) {
                font-size: 14px;
            }
        }
    }

    &_item {
        margin-bottom: 100px;

        &:hover {

            .fonctionnement_link_arrow {
                transform: translateX(5px);

            }
        }
    }

    &_link {
        display: flex;
        align-items: center;
        margin: 0 auto;
        width: fit-content;
        @include transition;

        .fonctionnement_link_arrow {
            position: relative;
            display: inline-block;
            @include transition;
            margin-left: 5px;
        }

    }

    @media (max-width:768px) {
        .container {
            grid-template-columns: 1fr;

            .fonctionnement_left {
                display: flex;
                flex-direction: column;
                max-width: 70%;
                margin: 0 auto;
            }

            .fonctionnement_right {
                display: flex;
                flex-direction: column;
                max-width: 70%;
                margin: 0 auto;
            }

            .fonctionnement_center {
                max-width: 70%;
                margin: 0 auto;
            }

            .fonctionnement_text {
                font-size: 10px;
                margin: 10px auto 5px;
                min-height: 30px;
                max-height: 200px;
                overflow: hidden;

            }

            .fonctionnement_item {
                margin: 0 5px;
            }

            .fonctionnement_title {
                font-size: 17px;
                letter-spacing: -1px;
                margin-bottom: -7px;
            }

            .fonctionnement_link {
                font-size: 12px;
                font-weight: 500;
            }

            .fonctionnement_icon {
                max-width: 60px;
                margin-bottom: -17px;

                i {
                    font-size: 30px;
                }
            }
        }

        #round {
            width: 300px;
            height: 300px;
            margin: 40px 0;
            display: none;
        }
    }

}

@media (min-width:700px) and (max-width: 998px) {
    .fonctionnement .container {
        display: block;
    }

    #round {
        display: none;
    }

    .fonctionnement_left {
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
        max-width: 70%;
        margin: 0 auto;
        grid-gap: 20px;
    }

    .fonctionnement_right {
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
        grid-gap: 50px;
        max-width: 70%;
        margin: 0 auto;
    }

    .fonctionnement_center {
        max-width: 70%;
        margin: 0 auto;
    }

    .fonctionnement_text {
        font-size: 16px;
    }

    .about-us>.container {
        grid-template-columns: 1fr;
    }

    .about-us_img.about-us_img_pc {
        display: none;
    }
}