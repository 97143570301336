@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.imgEquipe{
   
    img{
        border-radius:50%;
        border:1px solid $blue-3;
        width: 60%;
        height:250px;
        margin:auto;
        margin-left:80px; 
        // margin-right:250px;
        @media screen and (max-width:1100px) {
            margin-left: 60px;
        }
      
           
    }
    h4{
        color:$blue-3;
        text-align: center;
        padding: 10px 0 0 10px;
        font-weight: bold;
    }
    p{
        text-align: center;
        padding-left:10px ;
    }

   
}

.testimonal-slider .react-multi-carousel-dot-list {
    bottom: -80px;
}

.multi-item-carousel li {
    display: flex;
    height: fit-content; 
    align-items: center;
}
