
@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.checkboxMail{
	.checkbox{

		display: flex;
		justify-content: space-between;
		
	}
	span, label {
		font: 1rem 'Fira Sans', sans-serif;
	}
	span{
		text-decoration: underline;
	}
	fieldset{
		margin-top:15px;
	}
	legend{
		color:$blue-3;
	}
	input[type="checkbox"] {
		margin: .4rem;
	}
	.categorie input{
		margin-left:3rem;
		
	}

}

.background{
	background-color: #fff;
	padding-top: 25px;
}

.reponseTrue{
	text-align: center;
	padding-top: 15px;
	color:green;
}
.reponseError{
	text-align: center;
	padding-top: 15px;
	color:red;
}