@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";


.background-image-mad-up-pro {
  
    .react-parallax {
        height: 700px;
     
        margin: 50px 0 0;
        @media (max-width:1024px) {
            height: 400px;
           
        }
        @media (max-width:767px) {
            height: 300px;
            img {
              
            height: auto !important;
            }
        }
    }

   
}
.update{
    margin-top:25px;
    margin-bottom:70px;
}
.mad-up-pro {
    

    .button {
        text-transform: uppercase;
        margin: 0 auto 100px;
        display: flex;

    }

    &_about {
        margin-top: 70px;

        .container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 100px;
            margin-bottom: 120px;

            @media (max-width:1100px) {
                grid-template-columns: 1fr 
            }
        }
     
       
    }

    nav {
        margin-bottom: 20px;
    }

    &_list_item {
        display: grid;
        grid-template-columns: 60px 1fr;
        grid-gap: 10px;
        align-items: center;

        p {
            margin: 0 !important;
            padding: 0 !important;
        }

        margin-bottom: 10px;
    }

    &_icon {
        width: 60px;
        height: 60px;
        background: url(https://mad-up.com/aplications-icon.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 30px;
            color: #0c3146;
        }
        img {
            max-width: 30px;
        }


    }

    .video-react-poster {

        background-size: cover !important;
    }

    .video-react-big-play-button {
        width: 80px !important;
        border-radius: 50% !important;
        border: 5px solid #fff !important;
        height: 80px !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);

        &:before {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .video-react {
      
        border-radius: 10px;
        overflow: hidden;
    }

    @media (max-width:768px) {
        .video-react {
            grid-row: 1;
            border-radius: 10px;
            overflow: hidden;
        }
    }
}

.dotted {

    &_right {
        position: relative;

        &:after {
            position: absolute;
            opacity: 0.45;
            top: -10px;
            content: '';
            height: 202px;
            width: 202px;
            background-image: -webkit-repeating-radial-gradient(center center, $color-blue, $blue-1 1px, transparent 0px, transparent 100%);
            background-image: -ms-repeating-radial-gradient(center center, $color-blue, $blue-1 1px, transparent 0px, transparent 100%);
            background-size: 16px 16px;
            z-index: 0;
            right: 0%;

            @media (max-width:768px) {
                right: -5%;
            }
        }
    }



    &_left {
        position: relative;

        &:after {
            position: absolute;
            opacity: 0.45;
            top: -10px;
            content: '';
            height: 202px;
            width: 202px;
            background-image: -webkit-repeating-radial-gradient(center center, $color-blue, $blue-1 1px, transparent 0px, transparent 100%);
            background-image: -ms-repeating-radial-gradient(center center, $color-blue, $blue-1 1px, transparent 0px, transparent 100%);
            background-size: 16px 16px;
            z-index: 0;
            left: -20%;

            @media (max-width:768px) {
                left: -5%;
            }
        }
    }


}

.pc-parallax {
    display: none;
    @media (min-width:998px) {
        display: block
    }
}

.mobile-parallax {
    display: block;
    @media (min-width:998px) {
        display: none
    }
}

.imgProduit{
    width: 100%;
    height: 400px;
    background-image: url('https://mad-up.com/api/uploads/mad-up/originals/Dispositif2.png');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;

    
   

    @media screen and (min-width:1920px) {
        // LARGE SCREEN
        height: 650px;
        
    }

    .buttonModal_brochure{

		color:#fff;
		font-size: 28px;
		z-index:100;
		font-weight: 700;
		background-color: $blue-3;
		padding:15px 70px;
	//	border: 3px solid #fff;
		display: block;
		width: 600px;
		text-transform: uppercase;
		text-align: center;
		margin: auto;
		@media screen and (max-width:768px) {
			width: 300px;
			font-size: 15px;
			padding: 20px 0;
			
		}
    }
  
}
.update  {
    margin-top:15px;
    margin-bottom: 100px;
}