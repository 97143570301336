@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.bradcrumb {
    text-align: center;
    padding: 40px 15px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    
    position: relative;
    @media (max-width:768px) {
        padding: 50px 15px;    
    }
    
    h1 {
       
        font-weight: 900;
        color: $blue-1;
        margin-bottom: 20px;
        text-transform: uppercase;
        @media (max-width:500px) {
            max-width: 300px;
            margin: 0 auto;
            font-size: 30px !important;
        }
    }
    h2 {
        color: $color-blue;
        @media (max-width:768px) {
            font-size: 19px;
        }
       
    }
}