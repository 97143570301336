@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";



.blog {
    display: grid;
    grid-template-columns: 25% 1fr;
    grid-gap: 40px;
    padding: 100px 0;
    
    .neumorphic-menu {
        .blog-sidebar_category_title {
            border: none;
            text-align: left;
        }

        .blog-sidebar_category {
            margin: 0;
           
        }

        .blog-sidebar_category_list div:last-child {
            border: none;
        }
    }

    @media (max-width:998px) {
        padding: 70px 0;
        display: flex;
        flex-direction: column-reverse;
    }
}

.blog-sidebar_label {
    color: #767676;
    background: #f7f7f7;
    padding: 10px 30px;
    font-weight: 500;

}

.blog-sidebar {
    @media (max-width:998px) {
        display: none;
    }

    @media (max-width:768px) {
        margin-top: 70px;
    }

    &_category {
        margin: 0 0 40px;
        box-shadow: 5px 5px 10px #d9d9d960,
            -5px -5px 10px #ffffff;
        @include transition();

        &:hover {
            box-shadow: 5px 5px 10px #d9d9d9,
                -5px -5px 10px #ffffff;
        }

        @media (max-width:768px) {
            box-shadow: none;
        }
    }

    &_category_title {
        color: $color-blue;
        font-weight: 700;
        text-transform: uppercase;
        border: 1px solid #ededed;
        padding: 10px 20px;
        //text-align: right;
    }

    &_category_list {
        div {
            border: 1px solid #ededed;
            padding: 10px 20px;
            border-collapse: collapse;
            @include transition;
            color: $color-1;
            position: relative;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
           // text-align: right;
            @media (max-width:768px) {
                font-size: 12px;
            }

            &.active {
                background: #f7f7f7;
                color: $blue-1;

                &:after {
                    opacity: 1;
                }

            }

            &:after {
                content: "";
                width: 3px;
                height: 100%;
                top: 0;
                left: 0;
                background: $blue-1;
                position: absolute;
                opacity: 0;
                @include transition;
            }

            &:hover {
                background: #f7f7f7;
                color: $blue-1;

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &_latest {
        //text-align: right;
        box-shadow: 5px 5px 10px #d9d9d960,
            -5px -5px 10px #ffffff;
        @include transition();

        &:hover {
            box-shadow: 5px 5px 10px #d9d9d9,
                -5px -5px 10px #ffffff;
        }

        @media (max-width:768px) {
            box-shadow: none;
        }

        nav>div {
            display: none;
            
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                display: grid;
            }

            &:nth-child(3) {

                @media (max-width:768px) {
                    border: none;
                }
            }

        }

        &_item {
            grid-template-columns: 80px 1fr;
            grid-gap: 20px;
            align-items: center;
            border: 1px solid #ededed;
            border-collapse: collapse;
            padding: 10px 20px;
            cursor: pointer;
            position: relative;
            display: grid;

            @media (max-width:768px) {
                grid-template-columns: 40px 1fr;
                grid-gap: 0px;
            }

            &:after {
                content: "";
                width: 3px;
                height: 100%;
                top: 0;
                left: 0;
                background: $blue-1;
                position: absolute;
                opacity: 0;
                @include transition;
            }

            &:hover {
                background: #f7f7f7;
                color: $blue-1;

                &:after {
                    opacity: 1;
                }
            }

        }

        &_image {
            width: 80px;
            height: 80px;
            background-position: center !important;
            background-size: cover !important;
            border-radius: 50%;
            display: block;
            margin-right: 10px;
            
            background: $grey-2;
            
            @media (max-width:768px) {
                width: 30px;
                height: 30px
            }
        }

        &_date {
            color: $grey-1;
            font-size: 15px;

            @media (max-width:768px) {
                font-size: 10px
            }
        }

        &_title {
            color: $blue-4;
            font-weight: 500;
            display: block;
            font-size: 15px;

            @media (max-width:768px) {
                font-size: 13px;
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 200px;
                padding: 0;
            }

        }

        &_text {
            display: flex;
            flex-direction: column;
            align-self: center;

            @media (max-width:768px) {
                font-size: 13px;

            }

        }

    }
}

.blog-pagination {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;

    @media (max-width:768px) {
        grid-template-columns: 1fr;
    }

    .pagination {
        grid-column: 1/3;
        justify-content: center;
        align-self: end;

        @media (max-width:768px) {
            grid-column: 1;
        }

        li {
            padding: 5px 15px;
            border-collapse: collapse;
            border: 1px solid $grey-2;
            cursor: pointer;
            @include transition;

            a {
                color: $blue-3;
                font-weight: 500;
                cursor: inherit;

            }

            &.disabled {
                border: 1px solid $grey-2;

                a {
                    color: $color-grey;

                }

            }

            &.active {
                border: 1px solid $color-3;
                cursor: default;
                background: $color-3;

                a {
                    color: $color-5;
                }
            }

            &:hover:not(.disabled) {
                border: 1px solid $blue-3;

            }

        }
    }
}

.blog-card {
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.12);
    @include transition;
    border-radius: 20px;
    overflow: hidden;
    height: max-content;
    cursor: pointer;

    &:hover {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    &_image {
        min-height: 250px;
        background: $grey-2;
        border-radius: 0 0 10px 10px;

        @media (max-width:768px) {
            min-height: 200px;
        }
    }

    &_top-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        padding: 10px 20px;
        height: 38px;
    }

    &_bottom-row {
        padding: 10px 20px;
    }

    &_category {
        color: $blue-1;
        font-size: 12px;
        text-transform: uppercase;
    }

    &_title {
        font-weight: 500;
        color: $color-1;
        font-size: 18px;
        position: relative;
        height: 40px;
        overflow: hidden;
        display: flex;
        align-items: center;

    }

    .button_text {
        color: $color-1;




    }

    .hyphen {
        background-color: $color-1;
    }

    &_text {
        margin: 20px 0 20px;
        color: $color-1;
        font-size: 14px;
        position: relative;
        height: 58px;
        overflow: hidden;

        &:after {
            content: '';
            /* Выводим элемент */
            position: absolute;
            /* Абсолютное позиционирование */
            right: 0;
            bottom: 0px;
            /* Положение элемента */
            width: 100%;
            /* Ширина градиента*/
            height: 30px;
            /* Высота родителя */
            /* Градиент */

            background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.18) 100%);
        }
    }
}

.blog-card_image {
    background-size: cover !important;
}