
@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.contact{
	.checkbox{
		display: flex;
		justify-content: space-between;
		margin:10px;
		@media screen and (max-width:1100px) {
			flex-direction: column;
			
		}
	}
	.formLabel{
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 50px;
		//margin:50px 0;
		@media screen and (max-width:1100px) {
			grid-template-columns: 1fr;
			grid-gap:2px;
		}
	}

	.input {
        border: 1px solid #eee !important;
        padding: 20px !important;
        height: 60px !important;
		
        &:focus {
            background: #ffffff;
            box-shadow: inset 6px 6px 12px #d9d9d9, 
            inset -6px -6px 12px #ffffff !important;
        }
		@media screen and (max-width:768px) {
			width:45%;
		}
    }

	.btn {
        border: 1px solid #eee !important;
        padding: 20px !important;
        height: 60px !important;
		
        &:focus {
            background: #ffffff;
            box-shadow: inset 6px 6px 12px #d9d9d9, 
            inset -6px -6px 12px #ffffff !important;
        }
		@media screen and (max-width:768px) {
			width:45%;
		}
    }

	.span {
		text-decoration: none;
	}

	.mb{
		margin:50px 0;
	}

	.button_submit {
		border: 0px;
		background-color: transparent;
	}

	.info{
		font-style: italic;
		font-size: small;
		padding: 15px;
	}

    .valid-feedback {
        text-align: right;
    }
    .form-validate.submit {
        color: #0d3247 !important;
        border: 1px solid #a6c0d0 !important;
    }
}

.reponseTrue{
	text-align: center;
	padding-top: 15px;
	color:green;
}

.reponseError{
	text-align: center;
	padding-top: 15px;
	color:red;
}