.neumorphic-menu {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    background: #fff;
    transition: all 500ms ease;
    border-radius: 40px 0 0 40px;
    display: none;
    padding: 20px 0;
    z-index: 100000;
    
    @media (max-width:998px) {
        position: fixed;
        right: 0;
        height: max-content;
        display: block;
        width: 300px;
        top: 50%;
        padding: 0;
        transform: translate(300px, -50%);
     

        &.active {
            transform: translate(0px, -50%);
        }

        &.unactive {
            transform: translate(300px, -50%);
        }
    }
}