@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.iconMap {
    a {
        color: $color-grey;
        font-size:4rem;
        text-align: left;
        display: block; 
       
        @include transition();

        &:hover {
            color: $color-2;
        }
 
    @media (max-width:768px) {
        text-align: center;
    }
}

}