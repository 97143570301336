@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

footer {
    background: $color-dark;
    padding-top: 50px;

    @media (max-width:768px) {
        text-align: center;
    }


    .logo {
        margin-bottom: 30px;
        display: block;

        @media (max-width:768px) {
            margin: 0 auto 30px;
        }
    }

    .contact {
        padding: 40px 0 20px;

        p {
            margin-bottom: 4px;
        }

        a {
            @include link(#949494, $color-4, 18px);
        }

    }

    p {
        color: #949494;
    }

    .grid {
        @include grid(3, 10px);

        @media (max-width:768px) {
            grid-template-columns: 1fr;
        }
    }

    .footer-link {
        display: block;

        a {
            display: block;
            color: $color-5;
            font-size: 18px;
            @include transition;

            &:hover {
                color: $color-4;
            }
        }
    }

    h4 {
        font-size: 18px;
        color: $color-5;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 20px;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -20px;
            height: 2px;
            width: 40px;
            background: $color-5;

            @media (max-width:768px) {
                left: 50%;
                transform: translateX(-50%);
            }
        }
        
    }
    .hover{
        &:hover{
            color: $blue-3;
        }
    }
    .copyright {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        border-top: 1px solid #3e3e3e;
        padding: 30px 0 16px;

        @media (max-width:768px) {
            margin-top: 30px;
            grid-template-columns: 1fr;
            padding: 30px 0 56px;
        }

        p {
            @media (max-width:768px) {
                font-size: 10px;
            }

        }

        a {
            @include a(#949494, $color-4, 16px);

            @media (max-width:768px) {
                @include a(#949494, $color-4, 12px);
            }
        }
    }

    form {

        input {
            &:focus {
                border: 2px solid $color-5;
            }

            border: 2px solid #464646;
            background: none;
            outline: none;
            padding: 5px 10px;
            color: $color-5;
            display: block;
            margin: 70px 0 40px;
            @include transition;

            @media (max-width:768px) {
                margin: 60px auto 20px;
            }

            &::placeholder {
                font-size: 12px;
                color: #464646;
                font-style: italic;
            }
        }
    }

    h5 {
        color: $color-5;
        margin-bottom: 20px;
    }

    .social-icons {
        @media (max-width:768px) {
            justify-content: center;
        }
    }

}