.neumorphic-button {
    display: none;
    position: absolute;
    left: -60px;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    border-radius: 30px 0 0 30px;
    background: #0c3146;
    color: #fff;
    
    cursor: pointer;

    @media (max-width:998px) {
        display: flex;
    }

    svg {
        font-size: 30px;
        rect {
            fill: #fff;
        }

    }

    &.active {

        svg {
            animation: none;
        }
    }

    &.unactive {
        svg {
            animation: spin360 8s linear infinite;
        }

    }
}

@keyframes spin360 {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}