
@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.checkboxMail{
	.formLabelactivation{
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 50px;
		//margin:50px 0;
		@media screen and (max-width:1100px) {
			grid-template-columns: 1fr;
			grid-gap:2px;
		}
	}
	span, label {
		font: 1rem 'Fira Sans', sans-serif;
	}

	.input {
        border: 1px solid #eee !important;
        padding: 20px !important;
        height: 60px !important;
        
        
        &:focus {
            background: #ffffff;
            box-shadow: inset 6px 6px 12px #d9d9d9, 
            inset -6px -6px 12px #ffffff !important;
        }
	@media screen and (max-width:768px) {
		
			width:45%;
		}
    }
	.mb{
		margin:50px 0;
	}

}

// .form{
// 	display:block;
// 	margin:auto;
// 	width: 800px;
	
// }
.reponseTrue{
	text-align: center;
	padding-top: 15px;
	color:green;
}
.reponseError{
	text-align: center;
	padding-top: 15px;
	color:red;
}

.flexSerie{
	display: flex;
	justify-content: space-between;
	
}
.pserie{
	font-size: 18px;
	border: 1px solid #eee;
	padding: 15.5px 0;
	
}
.pserieInput{
	width: 85% !important;
	
}
// #modal{
// 	display: none;
// }


.modalDevice {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	padding-top: 100px; /* Location of the box */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  .modal-content {
	margin:  auto ;
	border: 1px solid $blue-3;
	border-radius: 10px;
	background-color: #fefefe;
	padding: 20px;
	border: 1px solid #888;
	width: 50%;
	.close {
		color: #aaaaaa;
		float: right;
		font-size: 28px;
		font-weight: bold;
	  }
	  
	  .close:hover,
	  .close:focus {
		color: #000;
		text-decoration: none;
		cursor: pointer;
	  }
  }
.deviceCenter{
	p{
		margin:15px;
		text-align: center;
	}

.fa-envelope-circle-check{
	font-size: 5rem;
	text-align: center;
	display: block;
	margin:auto;
	color:$color-blue;
}
}
#modalActiviation{
	display: none;
}