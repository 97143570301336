@import "../../Scss/_variables.scss";
@import "../../Scss/mixins.scss";

.applications-page {
    .big-title {
        display: none;
    }

    .button {
        margin: 20px auto 70px;
        display: flex;
    }
}

@media (max-width: 768px) {
    h1 {
        font-size: 41px !important;
    }
}

.sub-application { 
    video{
        width:600px;
        @media screen and (max-width:1100px) {
            video:500px;
            
        }
    }
    .img {
        background: url(https://mad-up.com/api/uploads/mad-up/originals/bordureImage.jpg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 620px;
        height: 420px;
        justify-self: center;
        margin: 25px auto 30px;

        @media (max-width:768px) {
            width: 100px;
            height: 100px;
        }

        i {
            font-size: 150px;
            @media (max-width:768px) {
               font-size: 50px;
            }
        }

     
    }
    // .marge{
    //     margin-bottom: 15px;
    //     @media (min-width:1100px)and (max-width:1500px) {
    //         margin-bottom: 100px;
    //     }
    // }
    .grid-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;
        margin: 100px 0;
      
        @media (min-width:768px) and (max-width:998px) {
            grid-template-columns: 1fr;
            grid-gap: 65px;
        }
      
        @media (max-width:768px) {
          grid-template-columns: 1fr;
          grid-gap: 65px;
        }
    }
    
    .button {
        display: flex;
        margin: 0 auto 70px;
    }

    .text-padding {
        margin: 70px 0;
    }

    .title {
        font-size: 22px;
        text-transform: uppercase;
        color: $color-5;
        background: $blue-1;
        width: 100%;
        display: block;
        text-align: center;
        max-width: 350px;
        padding: 5px;
        margin: 10px auto 20px;
    }
  
    .big-title_small {
        font-size: 25px;
        color: $color-blue !important;
        display: block;
        max-width: 700px;
        padding-bottom: 15px;

        @media (max-width:768px) {
            font-size: 18px;
        }
    }

    h2 {
        max-width: 100%;
        color: $blue-3;
        font-weight: 700;
        text-transform: uppercase;


        &:before {
            content: "";
            position: absolute;
            left: -80px;
            width: 38px;

            height: 1px;
            top: 50%;
            background: $blue-3;
            transform: translateY(-50%);
            z-index: 1;
        }

        &:after {
            position: absolute;
            left: -50px;
            width: 10px;
            height: 10px;
            content: "";
            background: $color-blue;
            border-radius: 50%;
            top: 50%;
            margin-top: -5px;
            transform: translateY(-50%);
            bottom: -30px;
            transform: none;
            z-index: 1;
        }

        span {
            color: $color-blue;
            text-transform: uppercase;

        }
    
        
    }
    .margeP{
        margin-top: 55px;
    }
    .center{
        text-align: center;
        color:$blue-3;
        font-style: italic;
        font-size: 15px;
        padding: 0 50px;
    }
    blockquote p::before {
        content: '\201C';
    }
    
    blockquote p::after {
        content: '\201D';
    }

    video{
        @media screen and (max-width:778px) {
            width: 250px;
            
        }
    }
}