@media screen and (max-width:1100px) {
	video{
		width:680px;
	}	
}
@media screen and (max-width:1100px) {
	video{
		width:400px;
	}	
}
