@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";



.language {
    .dropdown-menu {
        min-width: 55px;
        max-width: 55px;
        padding: 40px 0 0;
        border: none;
        border-radius: 50px 50px 0 0;
        background: linear-gradient(145deg, #e6e6e6, #ffffff);
        z-index: 1;
        transform: translate3d(20px, 20px, 0px) !important;
        border: 1px solid #e0e0e0;
    }

    .btn-language {
        z-index: 2;
        background: none;
        box-shadow: none;
        font-size: 20px;
        padding: 0px;
        color: $color-grey;
        padding: 8px 0 5px 10px !important;
        margin: 5px 0px !important;
        width: 52px;
        height: 50px;
        border-radius: 50%;
        font-size: 18px;
        border-radius: 50px;

        background: linear-gradient(145deg, #ffffff, #e6e6e6);
        box-shadow: 5px 5px 10px #d9d9d9,
            -5px -5px 10px #ffffff;

        &:active,
        &:hover,
        &:focus {
            background: linear-gradient(145deg, #e6e6e6, #ffffff);
            box-shadow: 5px 5px 10px #d9d9d9,
                -5px -5px 10px #ffffff;

        }
    }

    .dropdown-item {
        padding: 10px 2px;
        text-align: center;
        font-weight: 700;
        color: $color-grey;
        margin: 3px;
        width: calc(100% - 6px);
        height: calc(100% - 6px);

        &:active,
        &:hover,
        &:focus {
            background: linear-gradient(145deg, #e6e6e6, #ffffff);
            box-shadow: inset 2px 2px 4px #d9d9d9,
                inset -2px -2px 4px #ffffff;
            color: #76787b;
        }
    }

}

.dopdowrn.language {
    text-transform: uppercase;

}

.btn-language {
    display: flex;
    align-items: center;
    margin-left: 30px !important;
    color: $color-grey;
    font-weight: 700;

    @media (max-width:768px) {
        padding: 20px !important;
        margin-right: 20px !important;
        margin-left: 0px !important;
    }

}

.drop-btn {
    background: none;
    border: none;
}