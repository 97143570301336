@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.fixed_buttons {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    // @media (max-width:768px) {
    //     top: auto; 
    //     bottom: 10px;
    //     left: 50%;
    //    transform: translateX(-50%);
    //    flex-direction: row;
    //     border-radius: 10px;
    //     overflow: hidden;
    //     .__react_component_tooltip {
    //         visibility: hidden;
    //     }
    // }
    .fixed_button {
        width: 80px;
        height: 120px;
        display: flex;
        flex-direction: column;
       // padding: 25px 0;
        align-items: center;
        justify-content: center;
        background: $color-blue;
        // border-bottom: 1px solid  $blue-5;;
        @include transition;
       
        &:hover {
            background: $blue-5;
        }
        svg {
            font-size: 45px;
            color:$color-5;
          
           
            
        }
       
    }
    @media (max-width:768px) {
        .fixed_button {
            width: 60px;
            height: 40px;  
        }   
        svg {
            font-size: 25px !important;
        }
    }
    .text{
        color:$color-5;
        text-align: center;
        font-size: 10px;
        padding: 10px 0;
        @media (max-width:1100px){
            font-size: 6px;
            padding: 0;
        }
        
    }
}