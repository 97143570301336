@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.contact-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    margin: 40px 0;

    &_title {
        color: $color-blue;
        font-weight: 500;
        min-height: 50px;
        @media (max-width:768px) {
            min-height: auto;
        }
    
    }

    div {
        margin-bottom: 7px;
    }

    @media (max-width:768px) {
        grid-template-columns: 1fr;
    }

}

.mentions-legales {
    padding: 100px 0;

    &_title {
        color: $color-blue;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 50px;
        margin-bottom: 10px;
        
    }

    .button {
        margin-top: 50px;
        text-transform: uppercase;
    }
}