.contact-item {
    align-self: center;
    justify-self: center;   
    text-align: center;
    width: 220px;
    height: 220px;
    padding: 20px;
    border-radius: 50%;
background: linear-gradient(145deg, #ffffff, #e6e6e6);
box-shadow:  22px 22px 45px #d9d9d9, 
             -22px -22px 45px #ffffff;
             display: flex;
             flex-direction: column;
             justify-content: center;
             align-items: center;
             transition: all 300ms ease;
    @media (max-width:768px) {
        width: 150px;
    font-size: 12px;
    height: 150px;
      font-size: 12px;
        justify-self: center;
          align-self: flex-start;
    }
             &:hover {
                background: linear-gradient(145deg, #e6e6e6, #ffffff) !important;
                box-shadow:  22px 22px 45px #d9d9d9, 
                             -22px -22px 45px #ffffff !important;
             }

    i {
        font-size: 50px;
    }
    svg {
        font-size: 40px; 
        margin-bottom: 10px; 
    }

    margin-top: 50px;

    &_text {
        max-width: 250px;
        margin: 0 auto;
        display: block;
        color: #4a4a4a;
        font-weight: 500;
        &:hover {
            color: #4c4c4c !important;
          
         }
    }
}

.contact {
    input {
        border: 1px solid #efefef !important;
        padding: 20px !important;
        height: 60px !important;
        font-size: 18px;
        
        &:focus {
            background: #ffffff;
            box-shadow: inset 6px 6px 12px #d9d9d9, 
            inset -6px -6px 12px #ffffff !important;
        }
    }

    label.active {
        transform: translateY(-23px) scale(0.8);
        top: 0 !important;
        background: #a6c0d0;
        padding: 2px 5px;
        color: #fff !important;
        line-height: normal !important;
        box-shadow:  6px 6px 12px #d9d9d9, 
             -6px -6px 12px #ffffff !important;
    }

    textarea {
        min-height: 200px;
        border: 1px solid #efefef !important;
        padding: 20px 10px !important;
        &:focus {
            background: #ffffff;
box-shadow: inset 6px 6px 12px #d9d9d9, 
            inset -6px -6px 12px #ffffff !important;
        }
    }

    .md-form {
        margin-top: 10px;
        margin-bottom: 10px;
        label {
            top: 0%;
            line-height: 38px;
            margin-left: 20px;
        }
    }

    .valid-feedback {
        text-align: right;
    }
    .form-validate.submit {
        color: #0d3247 !important;
        border: 1px solid #a6c0d0 !important;
    }
    .grid {
        height: fit-content;
        align-items: baseline;
    }
}
