@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.social-icons {
    display: flex;
    align-self: center;

    a {
        color: $color-grey;
        font-size: 25px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include transition();

        &:hover {
            color: $color-2;
        }
    }
    span{
        padding: 5px;
    }
}