.ModalExample__buttons .Button:last-child {
	margin-right: 0;
	margin-bottom: 0;
}
// .buttonModal{
       
// 	display: flex;
// 	align-items: center;
// 	margin:auto;
// 	margin-bottom: 10px;
// 	color: rgb(6, 15, 30);
// 	font-size: 14px;
// 	font-weight: 700;
// 	letter-spacing: 0px;
// 	height: 50px;
// 	min-width: 120px;
// 	position: relative;
// 	padding: 0px 20px;
// 	max-width: fit-content;
// 	&_text {
// 		color: rgb(6, 15, 30);
// 		font-size: 14px;
// 		font-weight: 700;
// 		letter-spacing: 0px;
// 		z-index: 2;
// 		text-transform: uppercase;
// 	}

// 	&:disabled {
// 		opacity: 0.5;
// 	}

// 	&:before {
// 		content: "";
// 		display: block;
// 		position: absolute;
// 		top: 0px;
// 		left: 0px;
// 		height: 100%;
// 		width: 72px;
// 		background-color: #55acdf;
// 		border-radius: 40px;
// 		transition: all 0.3s ease-out 0s;
// 	}

// 	.hyphen {
// 		width: 20px;
// 		height: 2px;
// 		margin-right: 9px;
// 		background-color: rgb(6, 15, 30);
// 		transform-origin: 50% 50%;
// 		z-index: 1;
// 	}

// 	&:hover {
// 		&:before {
// 			width: 100%;
// 		}

// 		.hyphen {
// 			animation: 1s linear 0s 1 normal none running kMPRhr;
// 		}
// 	}

//}