.scroll-effect {
        position: absolute;
        left: 10%;
        bottom: 5%;
        z-index: 2;
        opacity: 0.6;
        animation: wobble-ver-right 7.8s infinite both;

        &:hover {
                opacity: 1;

                .scroll-effect_text {
                        margin-top: 10px;
                        transform: rotate(0deg) !important;

                }
        }

        @media (max-width: 768px) {
                left: 10px
        }

        &_icon {
                color: #fff;
                font-size: 30px;
                display: block;
                justify-content: center;
                margin: 0 auto 10px;

                @media (max-width: 768px) {
                        margin: 0 auto 5px;
                        font-size: 20px;
                }

        }

        &_line {
                width: 1px;
                height: 70px;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                background: #fff;

                @media (max-width: 768px) {
                        height: 30px;
                }
        }

        &_text {
                margin-top: 30px;
                color: #fff;
                transform: rotate(90deg);
                animation: vibrate-1 0.3s linear infinite both;
                transition: all 300ms ease;

                @media (max-width: 768px) {
                        margin-top: 10px;
                        font-size: 10px;
                }
        }
}

@keyframes heartbeat {
        from {
                -webkit-transform: scale(1);
                transform: scale(1);
                -webkit-transform-origin: center center;
                transform-origin: center center;
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }

        10% {
                -webkit-transform: scale(0.91);
                transform: scale(0.91);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        17% {
                -webkit-transform: scale(0.98);
                transform: scale(0.98);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }

        33% {
                -webkit-transform: scale(0.87);
                transform: scale(0.87);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        45% {
                -webkit-transform: scale(1);
                transform: scale(1);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }
}

@keyframes wobble-ver-right {

        0%,
        100% {
                -webkit-transform: translateY(0) rotate(0);
                transform: translateY(0) rotate(0);
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        }

        15% {
                -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
        }

        30% {
                -webkit-transform: translateY(15px);
                transform: translateY(15px);
        }

        45% {
                -webkit-transform: translateY(-15px);
                transform: translateY(-15px);
        }

        60% {
                -webkit-transform: translateY(9px);
                transform: translateY(9px);
        }

        75% {
                -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
        }
}

.bradcrumb {
        .scroll-effect {
                bottom: 15%;
        }
}