
@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.contact{
	.checkbox{
		display: flex;
		justify-content: space-between;
		margin:10px;
		@media screen and (max-width:1100px) {
			flex-direction: column;
			
		}
	}
	.formLabel{
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 50px;
		//margin:50px 0;
		@media screen and (max-width:1100px) {
			grid-template-columns: 1fr;
			grid-gap:2px;
		}
	}

	.input {
        border: 1px solid #eee !important;
        padding: 20px !important;
        height: 60px !important;
		
        &:focus {
            background: #ffffff;
            box-shadow: inset 6px 6px 12px #d9d9d9, 
            inset -6px -6px 12px #ffffff !important;
        }
		@media screen and (max-width:768px) {
			width:45%;
		}
    }

	.btn {
        border: 1px solid #eee !important;
        padding: 20px !important;
        height: 60px !important;
		
        &:focus {
            background: #ffffff;
            box-shadow: inset 6px 6px 12px #d9d9d9, 
            inset -6px -6px 12px #ffffff !important;
        }
		@media screen and (max-width:768px) {
			width:45%;
		}
    }

	.span {
		text-decoration: none;
	}

	.mb{
		margin:50px 0;
	}

	.button_submit {
		border: 0px;
		background-color: transparent;
	}

	.info{
		font-style: italic;
		font-size: small;
		padding: 15px;
	}

    .valid-feedback {
        text-align: right;
    }
    .form-validate.submit {
        color: #0d3247 !important;
        border: 1px solid #a6c0d0 !important;
    }

	.dropdown {
		margin-left: -7px;
		margin-right: 7px;
		margin-top: 5px;
		margin-bottom: 5px;

		.dropdown-toggle{
			width: 100%;
			text-align: left;
			color: $color-1 !important;
			border: 1px solid #eee !important;
			padding: 20px !important;
			height: 60px !important;
			background-color: #ffffff !important;
			text-transform : none;
			font-family: "Montserrat",sans-serif;
			font-size: 16px;
			
			&:focus {
				background-color: #ffffff !important;
				box-shadow: inset 6px 6px 12px #c6a8a8, 
				inset -6px -6px 12px #ffffff !important;
			}
			&:hover {
				background: #dddddd !important;
				box-shadow: inset 6px 6px 12px #c6a8a8, 
				inset -6px -6px 12px #ffffff !important;
			}
		}

		.dropdown-item{
			color: $color-1 !important;
			background-color: $color-5 !important;
			&:hover{
				color: $color-5 !important;
				background-color: $blue-5 !important;
			}
		}
		
	}
}

.reponseTrue{
	text-align: center;
	padding-top: 15px;
	color:green;
}

.reponseError{
	text-align: center;
	padding-top: 15px;
	color:red;
}