@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.article {
    // border-bottom: 1px solid #eeeeee;
    .facebookfeed {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
    }
    &_photo {
        img {
            max-width: 100%;
            border-radius: 20px;
        }
    }

    &_data {
        span {
            font-size: 14px;
            padding: 0 3px;

            @media (max-width:768px) {
                font-size: 12px;
            }
        }

        p {
            margin: 0 !important;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0 10px;
        border-bottom: 1px solid #eeeeee;
    }

    &_category {
        font-weight: 400;
        color: $blue-1;
        text-transform: uppercase;
      

    }

    h3 {
        color: #05151e;
        font-weight: 700;
        margin: 30px 0 50px;
    }

    p {
        margin-bottom: 20px;
    }

    .grid-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 100px;
        margin-top: 40px;

        @media (max-width:768px) {
            grid-template-columns: 1fr;
            grid-gap: 0;
        }

        img {
            border-radius: 20px;

            @media (max-width:768px) {
                margin-bottom: 30px;
            }

        }
    }
}

.artical-social-image {
    max-width: 70px;
    cursor: pointer;
}

.article_comment {
    padding: 20px 30px;
    border: 1px solid #eeeeee;
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: center;
    max-width: calc(65% - 40px);
    margin: 0 0 0 auto;
    margin-bottom: 20px;

    position: relative;

    @media (max-width:768px) {
        max-width: 100%;

    }
}



.article_comment_image {
    width: 80px;
    height: 80px;
    background-position: center !important;
    background-size: cover !important;
    border-radius: 50%;
    display: block;
    margin-right: 20px;
    background: $grey-2;
}

.article_comment_name {
    display: flex;
    justify-content: space-between;

    b {
        font-weight: 700;
    }

    span {
        font-weight: 500;
        color: $blue-3;
    }
}

.comment {
    display: none;

    h3 {
        text-align: right;
        font-weight: 700;
        text-transform: uppercase;
    }

}
#blog_skeleton {
    border-radius: 20px;
    height: 400px;
}
