@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.iconBoutique {

    a {
        color: $color-blue;
        font-size: 32px;
        padding: 0px 50px 30px 0 !important;
        margin: 5px 5px !important;
        width: 52px;
        height: 50px;
        display:block;
        @include transition();

        &:hover {
            color: $blue-3;
        }
    }
}