
@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.checkboxMail{
	
	.checkbox{

		display: flex;
		justify-content: space-between;
		margin:10px;
		@media screen and (max-width:1100px) {
			flex-direction: column;
			
		}
		
	}
	.formLabel{
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 50px;
		//margin:50px 0;
		@media screen and (max-width:1100px) {
			grid-template-columns: 1fr;
			grid-gap:2px;
		}
	}
	span, label {
		font: 1rem 'Fira Sans', sans-serif;
	}

	.input {
        border: 1px solid #eee !important;
        padding: 18px !important;
        height: 60px !important;
        
        
        &:focus {
            background: #ffffff;
            box-shadow: inset 6px 6px 12px #d9d9d9, 
            inset -6px -6px 12px #ffffff !important;
        }
	@media screen and (max-width:768px) {
		
			width:45%;
		}
    }
	.mb{
		margin:50px 0;
	}

}

// .form{
// 	display:block;
// 	margin:auto;
// 	width: 800px;
	
// }
.reponseTrue{
	text-align: center;
	padding-top: 15px;
	color:green;
}
.reponseError{
	text-align: center;
	padding-top: 15px;
	color:red;
}
.select{
	padding:5px;
	margin-left:10px
}