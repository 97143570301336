@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.read_More {
    position: fixed;
    top: 50%;
    right:0;
    transform: translateY(50%);
    display: flex;
    flex-direction: column;
    font-weight: bold;
    // @media (max-width:768px) {
    //     top: auto; 
    //     bottom: 10px;
    //     left: 50%;
    //    transform: translateX(-50%);
    //    flex-direction: row;
    //     border-radius: 10px;
    //     overflow: hidden;
    //     .__react_component_tooltip {
    //         visibility: hidden;
    //     }
    // }
    .read_More {
        width: 180px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        display: flex;
        flex-direction: row;
        padding: 3px 0;
        align-items: center;
        justify-content: space-evenly;
        background: $color-blue;
        // border-bottom: 1px solid  $blue-5;;
        @include transition;
       
        &:hover {
            background: $blue-3;
        }
        .fa-solid  {
            font-size: 30px;
            color:$color-5;
            border:1px solid #fff;
            border-radius: 50%;
            padding: 7px ;
          
          
           
            
        }
       
    }
    @media (max-width:768px) {
        .read_More {
            width: 85px;
           
        }   
        .fa-solid  {
            font-size: 15px !important;
        }
    }
    .text{
        color:$color-5;
        text-align: center;
        font-size: 15px;
        padding: 8px 18px;
    
        @media (max-width:768x){
            font-size: 8px;
            padding: 0;
        }
        
    }
}