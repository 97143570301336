@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

header {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 10000;
  position: fixed;
  top: 0;
  margin: 0;
  display: block;
  width: 100%;
  background:	#fff;

  .logo-wrapper {
     //margin: 10px 0;
    align-self: center;
    display: flex;
    align-items: center;
   
    @media (max-width: 768px) {
      margin: 0;
      .logo {  
        img {
          max-width: 135px;
          // margin: auto;
          // text-align:center;
          margin-left: 2px;
         
        }
        a{
          padding: 0;
        }
      }
    
   }


    @media (max-width: 374px) {
      .logo {
        img {
          max-width: 100px;
        }
      }
    }
  }


  .header-certificates {
    margin-left: 20px;

    .certificate-item {
      &:nth-child(1) {
        max-width: 23px;
      }

      &:nth-child(2) {
        max-width: 40px;
      }

      &:not(:last-child) {
        margin-bottom: 7px;
      }
      img{
        display: block;
      }
    }
  }
  
  
   
    @media (max-width: 1100px) {
        color: #fff;
        position: relative;
        &::after{
          content:'';
          width: 100%;
          height: 1px;
          border-radius: 4px;
          position: absolute;
          left:0;
          bottom: 0;
          transform: scaleX(0);
          transform-origin: left;
          transition: transform .25s ease;
          &:hover::after{
            transform: scaleX(1);
          }
        }
    }
  }

  .right-nav{
    a {
      font-size: 18px;
      color: #000;
      // @include transition;
      // display: block;
      padding: 10px;
      margin-bottom: 30px;
      text-transform: uppercase;
      text-decoration: none;
      @media screen and (max-width:1100px){
        color:#fff;
        
      }
      &:hover {
        color: #000;
       
       
        @media(min-width:1100px){
          background-color: $grey-3;
          // color:#000;
        }
        
      }
  }
  // .container {
  //   padding: 0 15px;
  // }
  
  @media (min-width: 575.98px) {
    .containerHeader {
      max-width: 540px;
      margin: auto;
      padding: 0;
    }
  }
  
  @media (min-width: 767.98px) {
    .containerHeader {
      max-width: 720px;
    }
  }
  
  @media (min-width: 991.98px) {
    .containerHeader {
      max-width: 950px;
    }
  }
  
  @media (min-width: 1199.98px) {
    .containerHeader {
      max-width: 1000px;
    }
  }
  
  @media (min-width: 1500.98px) {
    .containerHeader {
      max-width: 1200px !important;
    }
  }
}


    .right {
      display: flex;
      justify-content: space-around;
      align-items: center;
      // @media (max-width: 768px) {
      //   flex-direction: row-reverse;
      // }
    }

#root {
  @media (max-width: 1100px) {
    margin-top: 60px;

    header {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
    }
  }
}
.flex{
  display:flex;
  align-items:center;
  flex-direction: row-reverse;
  align-items: center;
  justify-content:space-around;
  @media (max-width: 768px) {
    flex-direction: row-reverse;
    
  }           

}
//Sous menu
@media (min-width: 1101px){

.dropdown{
  position: relative;
  
}
.dropdown-content {
  display:none;
  list-style:none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 8px;
  z-index: 1;
  border-top:2px solid #15acf2; 
  margin-top:4px;
   li{
    padding:20px 10px;
    }
  a{
    padding: 10px;
  }  
}


.dropdown > a:after{
  content: '❯';
  font-size: 15px;
  margin-left: 7px;
  display: inline-block;

}

.dropdown:hover .dropdown-content {
  display: block;
}
//animation flèche
.dropdown:hover > a:after{
  animation: rotationFleche 0.2s linear forwards;
}

@keyframes rotationFleche {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100%{
    transform: rotate(90deg);
  }
}
}

