@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.equipeDetail{
    border:1px solid $blue-3;
    border-radius: 10px 10px 10px 10px;
    width: 710px;
    height:350px;
  
    @media screen and (max-width:768px) {
      height: 690px;
      border:0;
      width: max-content;
      
    }
   
    img{
        width: 280px;
       // height: 330px;
        border-radius: 15px 15px 15px 15px;
        padding: 10px;
        margin-top: 35px;
        margin-left: 10px;
       
        
     
    }

    

    .flexequipe{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        @media screen and (max-width:768px) {
          flex-direction:column ;
          
        }
      
        
    }
    .detailText{
      h3{
            text-align: center;
            color:$blue-3;
            font-weight: bold;
           margin-top: 50px;
      }
      span{
        display: block;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
      }
        
    }
    p{
        padding-right: 15px ;
        @media screen and (max-width:768px) {
         text-align: center;
         padding-right: none;
          
        }
    }
   
}