@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

//Pour les images

.bfrexpert {
    .long {
        .big-title {
            span {
                 display: block !important;
             }
         }
    }

    .grid-2 {
        align-items: center;
        margin:50px 0 !important;
        img {
            border-radius: 10px;
        }
    }
    .grid-3{
        display: grid;
        grid-gap: 50px;
        align-items: center;
        margin: 30px ;
        align-self: auto;
      //mode tablette
        @media (min-width:768px) and (max-width:1100px) {
          grid-gap: 100px;
          grid-template-columns: 1fr 1fr;
        }
      //mode mobile
        @media (max-width:768px) {
          grid-template-columns: 1fr;
          grid-gap: 20px;
        }
    }

    .flexApplication{
        margin:auto;
        display:flex;
        align-items: center;
        justify-content: space-around;
      @media screen and (max-width:1100px) {
        flex-direction: column;;
        
      }
    }

    .flexButton{
      display: flex;
      justify-content: space-around;
      // @media screen and (min-width:1100px){
      //   display: none;
      // }
    }
    .margeApplication{
      margin-top:150px;
    }
}

