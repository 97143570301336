@media screen and (max-width:1100px) {
	video{
		width:480px;
	}	
}
@media screen and (min-width:1100px) {
	video{
		width:720px;
		display: block;
		
		padding: 5px;
	
	}	
}
.ModalContainer__content{
	background: none;
}
