
@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.ModalExample__buttons .Button:last-child {
	margin-right: 0;
	margin-bottom: 0;
}
.buttonModal_brochure{

		color:#fff;
		font-size: 28px;
		z-index:100;
		font-weight: 700;
		background-color: $blue-3;
		padding:15px 70px;
	//	border: 3px solid #fff;
		display: block;
		width: 600px;
		text-transform: uppercase;
		text-align: center;
		margin: auto;
		@media screen and (max-width:768px) {
			width: 300px;
			font-size: 15px;
			padding: 20px 0;
			
		}

}