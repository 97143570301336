@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.grid-image-1 {
    @media (max-width:768px) {
        grid-row: 1;
    }
}

.grid-image-2 {
    @media (max-width:768px) {
        grid-row: 3;
    }
}




.Aproposimage{
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width:767px) {
      flex-direction: column;
  }
    .card{
        position:relative;
        text-align: center;
        overflow: hidden;
        width: 600px;
        height:700px;
        background-color:#eee;
        border-radius: 10px;
        outline: 3px solid $color-3;
        box-shadow: 3px 3px 10px rgba(0,0,0,.1);
        outline-offset: -8px;
        margin:20px 0;
        @media screen and (max-width:767px) {
          width: 300px;
          height: 350px;

      }

      @media screen and (max-width:1100px) {
          margin-top: 0;

      }
      }
      .card-image{
        width: 100%;
        height: 100%;
        position: absolute;
        inset:0;
        display: flex;
        margin:auto;
        object-fit: cover;
        outline: 3px solid $color-3;
        outline-offset: -8px;
        transition: transform .75s;
        @media screen and (max-width:767px) {
          width: 300px;
          height: 350px;

      }
      
      }
        .card-image:hover{
        transform: scale(1.10);
        filter: opacity(100%);
       outline: 10px solid $color-3;
        outline-offset: -50px;
      }
      .card-title, .card-title1, .card-title2{

        margin: 0;
        text-align: center;
        color:$blue-4;
         position:relative;
        text-shadow: 2px 3px 4px #f4eeee80;
        font-weight: bold;
        font-size: 25px;
         padding: 25px 0;
      
        text-transform: uppercase;
        // @media screen and (min-width:1600px){
        //   left:45px;
        // }
      //   @media screen and (max-width:1100px) {
      //     //writing-mode: vertical-lr;
      //     // text-orientation: mixed;
      //     // transform: rotate(-180deg);
      //     // bottom: 55px;
      //     // left: 8px;
      //    // font-size: 35px;
      //   }
        @media screen and (max-width:768px) {
          font-size: 20px;

      }
      }
      // .card-title1{
   
      
      //   left: 70px;
      //   @media screen and (max-width:1100px) {
      //     bottom: 55px;
      //     left: 8px;
      //   }
      // }
      // .card-title2{
       
      //   left:140px;
      //   @media screen and (max-width:1100px) {
      //     bottom: 55px;
      //     left: 0;
            
         
      //   }
      // }
}

.grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 60px;
  margin: 50px 0;

  @media (min-width:768px) and (max-width:998px) {
    grid-gap: 50px;
  }

  @media (max-width:768px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  h2{
    color:$blue-4;
    text-align: center;
  }
}

.margeApropos{
  margin-top: 100px;
  margin-bottom: 150px;
  
}
.DetailMobile{
  @media screen and (min-width:768px) {
    display: none;
    margin: 250px 0;
    
  }
}

.carouselDesktop{
  @media screen and (max-width:768px) {
    display: none;
    
  }
}

.videoDelis{
  @media screen and (max-width:768px) {
    width: 300px;  
  }
  
}